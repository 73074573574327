export const urls = {
    localhost: (window.location.hostname === ('localhost')) ? 'https://mspbeta.cyrisma.com' : `https://${window.location.hostname}:51808`,
    thisInstance: (window.location.hostname === ('localhost')) ? 'https://mspbeta.cyrisma.com' : `https://${window.location.hostname}`,
    msp: (window.location.hostname === ('localhost')) ? 'https://mspbeta.cyrisma.com' : `https://${window.location.hostname}`,
    support: 'https://cyrisma.supportsystem.com/'
}

export const endpoints = {
    rest: '/app/rest/',
    msp: '/app/msp/',
    secureScore: '/app/SecureScore/',
    msGraphMailboxes: '/app/MSGraph/Mailboxes',
    msGraphSharepoints: '/app/MSGraph/Sharepoints',
    mfa: '/app/login/MSP/MFA',
    gmail: '/app/Google/Users',
    darkWebReports: '/app/darkweb/report/consumer/data/',
    evidences: '/app/evidence',
    psa: '/app/psa/'
}

export const PROMISE_STATUS = {
    fulfilled: 'fulfilled',
    rejected: 'rejected',
}

export const REST = 'rest'
export const MSP = 'msp'
export const PSA = 'psa'

export const LOGIN_BOTTOM_INFO_TEXT = 'CYRISMA MSP is for the use of authorized users only. Individuals using CYRISMA without authority, or in excess of their authority may result in legal action. All activities are logged and recorded and can be used as evidence in a court of law.'

export const confirmationText = {
    server: 'This final delete will remove license and registration keys plus all users associated with this organization.BEFORE proceeding it is important to remove DNS entries for this instance seperately.Have you Removed DNS? Ready to proceed?',
    removeInstance: 'You are about to uninstall and remove an instance of the CYRISMA Command Center. This is a permanent action that removes all data associated with this instance and removes the CYRISMA Command Center Website.This action cannot be undone and data associated with this instance cannot be recovered.',
    organisation: 'You are about to uninstall and remove an instance of the CYRISMA Command Center.This is a permanent action that removes all data associated with this instance and removes the CYRISMA Command Center Website.This action cannot be undone and data associated with this instance cannot be recovered.'
}

export const isFFBrowser = /Firefox\//.test(navigator.userAgent) ? '1' : '0'